
import { Component, Vue } from "vue-property-decorator";
import { $auth, AuthRole } from "@/pages/auth/services/auth.service";
import { getDomainByBasicInformation } from "@/api/account"
import broadcast, { MESSAGE_TYPES } from '@/utils/broadcast';
import { getCurrentMainDomain } from '@/utils/domain';
@Component({
  name: "login-page"
})
export default class LoginPage extends Vue {
  urlData = '';

  codeImageUrl = '';
  roles = Object.values($auth.roles);
  currentRole: AuthRole = $auth.buildRole();
  form = $auth.form;
  mobileForm = $auth.mobileForm;
  loading = false;
  isMounted = false;
  activeName = 'account';
  smsSuffixText = '获取验证码';
  smsTimeout = 0;
  smsCount = 0;

  get pwdCover() {
    return new Array(this.form.pwd.length).fill('●').join('');
  }

  mounted() {
    if (location.host.indexOf('192.168') === -1 && location.host.indexOf('sshlqf') === -1) {
      const endUrl = getCurrentMainDomain() // 获取当前地址栏后缀主域名地址
      getDomainByBasicInformation(endUrl).then(res => {
        this.urlData = res.text
        document.title = res.name;
        this.handleIcoCreate(res.logo)
      })
    } else {
      document.title = '山水互联';
      this.urlData = '山南水北（北京）生态科技发展有限公司 | 京ICP备2020039568号-1';
      const imgUrl = 'https://sshlwap.oss-cn-beijing.aliyuncs.com/files/normal/56690F1AB073464CB6604A0BCE8E2758.png';
      this.handleIcoCreate(imgUrl)
    }
    this.refreshCode();
    setTimeout(() => {
      this.isMounted = true;
    }, 500);
  }

  post() {
    // 在账号更改后发送通知：
    broadcast.postMessage(MESSAGE_TYPES.ACCOUNT_CHANGED, {
      userId: 'new_user_id',
      timestamp: Date.now()
    });
  }

  handleIcoCreate(icoUrl) {
    const link: any = document.querySelector("link[rel*='icon']") || document.createElement('link');
    link.href = icoUrl;
    document.getElementsByTagName('head')[0].appendChild(link);
  }

  async clickSmsSuffixText() {
    let is: any
    await (this.$refs.mobileFormRef as any).validateField('mobile', (valid: any) => void (is = valid))

    if (this.smsTimeout || is) return
    const { mobile, captcha } = this.mobileForm
    if (!/^1[3456789]\d{9}$/.test(mobile)) return
    await $auth.smscodeDropShipping(mobile, captcha || undefined)
    let timer: any = null
    this.smsTimeout = 60
    this.smsSuffixText = '60秒'
    timer = setInterval(() => {
      this.smsTimeout--;
      if (this.smsTimeout <= 0) {
        this.smsSuffixText = '获取验证码';
        this.smsTimeout = 0;
        clearInterval(timer);
      } else {
        this.smsSuffixText = this.smsTimeout + '秒';
      }
    }, 1000);
  }

  refreshCode() {
    this.codeImageUrl = this.currentRole.getCodeImageUrl('LOGIN');
    this.$forceUpdate();
  }

  switchRole(role) {
    this.currentRole = $auth.switchRole(role);
    $auth.clearForm();
    this.refreshCode();
  }

  removeAccount(ref, value: string) {
    this.currentRole.removeAccount(value);
    ref.debouncedGetData(ref.value);
  }

  handleSelectAccount(account) {
    $auth.selectAccount(account.value, account.pwd);
    this.$forceUpdate();
  }

  async login(formRef) {
    try {
      const valid = await formRef.validate();
      console.log(valid);

      if (!valid) return;
      this.loading = true;
      try {
        if (this.activeName === 'account') await $auth.login();
        else if (this.activeName === 'mobile') {
          try {
            await $auth.loginDropShipping();
          } catch (error) {
            this.refreshCode();
            this.smsCount++
          }
        }
      } catch (error) {
        this.refreshCode();
      } finally {
        this.loading = false;
      }
    } catch (error) {

    }
  }
}
